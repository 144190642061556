export default new class Config {
    config = {
        apiUrl: process.env.VUE_APP_API_URL,
        images: process.env.VUE_APP_API_IMAGES,
    }
    getConfig() {
      // Use vue-resource or any other http library to send your request
      return this.config;
    }
    
  }