<template>
  <!-- Main Sidebar Container -->
  <aside class="main-sidebar">
    <!-- Brand Logo -->
    <router-link to="/dashboard/indicators" class="brand-link">
      <img
        src="/logo-color.png"
        alt="Primax Logo"
        style="position: relative; width: 80px; margin: 0 auto; display: block;"
      />
    </router-link>

    <!-- Sidebar -->
    <div class="sidebar">
      <!-- Sidebar Menu -->
      <nav class="mt-4">
        <ul
          class="nav nav-pills nav-sidebar flex-column"
          data-widget="treeview"
          role="menu"
          data-accordion="false"
        >
          <!-- Add icons to the links using the .nav-icon class
                 with font-awesome or any other icon font library -->
          <li class="nav-item has-treeview">
            <router-link to="/dashboard/stations" class="nav-link">
              <i class="nav-icon fas fa-building"></i>
              <p>EDs</p>
            </router-link>
          </li>

          <li class="nav-item has-treeview">
            <router-link to="/dashboard/users" class="nav-link">
              <i class="nav-icon fas fa-user"></i>
              <p>Usuarios</p>
            </router-link>
          </li>

          <li class="nav-item">
            <router-link to="/dashboard/points" class="nav-link">
              <i class="nav-icon fab fa-product-hunt"></i>
              <p>Puntos</p>
            </router-link>
          </li>
          <li class="nav-item has-treeview">
            <router-link to="/dashboard/news" class="nav-link">
              <i class="nav-icon far fa-newspaper"></i>
              <p>Noticias</p>
            </router-link>
          </li>
          <li class="nav-item has-treeview">
            <router-link to="/dashboard/indicators" class="nav-link">
              <i class="nav-icon fas fa-chart-line"></i>
              <p>Indicadores</p>
            </router-link>
          </li>
          <li class="nav-item has-treeview">
            <router-link to="/dashboard/tickets" class="nav-link">
              <i class="nav-icon fas fa-clipboard-list"></i>
              <p>Tickets</p>
            </router-link>
          </li>
           
          <li class="nav-item has-treeview">
            <router-link to="/dashboard/training" class="nav-link">
              <i class="nav-icon fas fa-graduation-cap"></i>
              <p>Entrenamiento</p>
            </router-link>
          </li>

          <li class="nav-item has-treeview">
            <router-link to="/dashboard/banners" class="nav-link">
              <i class="nav-icon fas fa-ad"></i>
              <p>Banners</p>
            </router-link>
          </li>

          <li class="nav-item has-treeview">
            <router-link to="/dashboard/visits" class="nav-link">
              <i class="nav-icon fas fa-glasses"></i>
              <p>Visitas
              </p>
            </router-link>
          </li>

          <li class="nav-item has-treeview">
            <router-link to="/dashboard/surveys" class="nav-link">
              <i class="nav-icon fas fa-poll-h"></i>
              <p>Encuestas
              </p>
            </router-link>
          </li>

          <li class="nav-item has-treeview">
            <router-link to="/dashboard/faqs" class="nav-link">
              <i class="nav-icon fas fa-question"></i>
              <p>FAQ</p>
            </router-link>
          </li>

          <li class="nav-item has-treeview">
            <router-link  to="/" class="nav-link" @click="logout">
              <i class="nav-icon fas fa-sign-out-alt"></i>
              <p>Cerrar session</p>
            </router-link>
          </li>
        </ul>
      </nav>
      <!-- /.sidebar-menu -->
    </div>
    <!-- /.sidebar -->
  </aside>
</template>

<script>
import config from "@/config/config";
import userService from "@/services/userService";
export default {
  name: `Menu`,
  data() {
    return {
      config: config.getConfig(),
    };
  },
  methods:{
    logout(){
      userService.logoutUser();
    }
  }
};
</script>

<style lang="scss">
.router-link-active {
  color: #666 !important;
}
</style>
