import config from '@/config/config';
import Axios from 'axios';
import { handleHTTPError } from "../lib/http";

export interface UsersPaginatedInput {
  stations?: string[];
  roles?: ('admin' | 'coach' | 'manager' | 'seller' | 'user')[];
  sortBy?: {
    field: 'name' | 'lastName' | 'role' | 'created_at';
    dir?: 'asc' | 'desc';
  }[];
  page?: number;
  search?: string;
  pageSize?: number;
}

export default new class UserService {
  public config = config.getConfig();

  async verifiLogin() {
    // Use vue-resource or any other http library to send your request
    const token = this.getToken();
    try {
      if (token) {
        const response = (await Axios.post(`${this.config.apiUrl}/checktoken`, {}, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        })).data;
        if (!response) {
          this.saveToken('');
          return false;
        }
      }

      const tok = this.getToken();

      if (tok) {
        return true;
      }
    } catch (e) {
      this.saveToken('');
      return false;
    }
  }

  async login(data: { email: string; password: string }) {
    try {
      const response = await Axios.post(`${this.config.apiUrl}/authenticate`, data)

      return response.data;
    } catch (e) {
      if (e.response) {
        return e.response.data;
      }

      throw e
    }
  }

  saveToken(token: string) {
    localStorage.setItem('__token', token);
    return true;
  }

  logoutUser() {
    localStorage.setItem('__token', '');

    return true;
  }

  getToken() {
    return localStorage.getItem('__token');
  }
  async getUsersData(startDate = '', endDate = '', all = true) {
    const token = this.getToken();
    try {
      return (await Axios.get(`${this.config.apiUrl}/user/totalUsers${startDate !== '' && endDate !== '' ? `?startDate=${startDate}&endDate=${endDate}&all=${all}` : `?all=${all}`}`,
        {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        })).data;
    } catch (e) {
      if (!e.response) {
        // network error
        return { network: "Network Error" };
      }
      return e.response.data;
    }
  }

  async getPageViews(startDate = '', endDate = '', type = 'all') {
    const token = this.getToken();
    try {
      return (await Axios.get(`${this.config.apiUrl}/totalPageViews${startDate !== '' && endDate !== '' ? `?startDate=${startDate}&endDate=${endDate}&type=${type}` : ''}`,
        {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        })).data;
    } catch (e) {
      if (!e.response) {
        // network error
        return { network: "Network Error" };
      }
      return e.response.data;
    }
  }

  async getUserTopics() {
    const token = this.getToken();
    try {
      return (await Axios.get(`${this.config.apiUrl}/userTopics`,
        {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        })).data;
    } catch (e) {
      if (!e.response) {
        // network error
        return { network: "Network Error" };
      }
      return e.response.data;
    }
  }

  async getUserTypes() {
    const response = await Axios.get(`${this.config.apiUrl}/user/types`, {
      headers: { 'Authorization': `Bearer ${this.getToken()}` },
    });

    return response.data;
  }

  async getRoles() {
    const token = this.getToken();
    try {
      return (await Axios.get(`${this.config.apiUrl}/roles`,
        {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        })).data;
    } catch (e) {
      if (!e.response) {
        // network error
        return { network: "Network Error" };
      }
      return e.response.data;
    }
  }

  async getUsersRole(role = 'user') {
    const token = this.getToken();
    try {
      return (await Axios.post(`${this.config.apiUrl}/get-users-role`, { role },
        {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        })).data;
    } catch (e) {
      if (!e.response) {
        // network error
        return { network: "Network Error" };
      }
      return e.response.data;
    }
  }

  async createUser(data: Record<string, string|number|boolean>) {
    const token = this.getToken();
    const url = `${this.config.apiUrl}/create-users`;

    try {
      const {data:responseData} = await Axios.post(url, JSON.stringify(data), {
          headers: {
            accept: 'application/json',
            'content-type': 'application/json',
            'Authorization': `Bearer ${token}`
          }
        })
       
        return responseData.user;
    } catch (e) {
      handleHTTPError(e)
    }
  }

  async getOneUser($id: string|number) {
    const token = this.getToken();
    try {
      return (await Axios.get(`${this.config.apiUrl}/get-one-user/${$id}`,
        {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        })).data;
    } catch (e) {
      if (!e.response) {
        // network error
        return { network: "Network Error" };
      }
      return e.response.data;
    }
  }

  async updateUser(id: string|number, $data: Record<string, string|number|boolean>) {
    const token = this.getToken();
    const response = await Axios.put(
      `${this.config.apiUrl}/update-user/${id}`,
      $data,
      {headers: {authorization: `Bearer ${token}`}},
    )

    return response.data;
  }

  async updatePassword(id: string, password: string) {
    const token = this.getToken();
    const response = await Axios.post(
      `${this.config.apiUrl}/user/${id}/password`,
      {password},
      {headers: {'Authorization': `Bearer ${token}`}},
    );

    return response.data;
  }

  async deleteUser($id: string|number) {
    const token = this.getToken();
    const url = `${this.config.apiUrl}/delete-user/${$id}`;

    try {
      const response = await Axios.delete(url, {
        headers: {'Authorization': `Bearer ${token}`}
      })

      return response.data;
    } catch (e) {
      if (!e.response) {
        throw e
      }

      return e.response.data;
    }
  }

  async forgetPassword(email: string) {
    console.log('Recover password');
    try {
      return (await Axios.post(`${this.config.apiUrl}/recoverPassword`, { email }, {
      })).data;
    } catch (e) {
      if (!e.response) {
        // network error
        return { network: "Network Error" };
      }
      return e.response.data;
    }
  }

  async getAllUsersPaginated(input?: UsersPaginatedInput) {
    const search = new URLSearchParams()

    if (input.page) {
      search.set('page', input.page.toString())
    }

    if (input.search) {
      search.set('search', input.search)
    }

    if (input.pageSize) {
      search.set('pageSize', input.pageSize.toString())
    }

    if (input.roles) {
      for (const index in input.roles) {
        search.set(`roles[${index}]`, input.roles[index])
      }
    }

    if (input.stations) {
      for (const index in input.stations) {
        search.set(`stations[${index}]`, input.stations[index])
      }
    }

    if (input.sortBy) {
      for (const index in input.sortBy) {
        search.set(`sortBy[${index}][field]`, input.sortBy[index].field)
        search.set(`sortBy[${index}][dir]`, input.sortBy[index].dir || 'asc')
      }
    }

    const url = `${this.config.apiUrl}/user/list?${search.toString()}`;
    const options = { headers: { 'Authorization': `Bearer ${this.getToken()}` } }

    const response = await Axios.get(url, options)

    return response.data;
  }
};
