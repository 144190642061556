import { createApp } from 'vue'
import router from './router'
import store from './store'
import ECharts from './lib/echarts'
import { formatDate } from './lib/date'
import { setupHTTPClient } from './lib/http'
import App from './App.vue'
import Layout from "./layout/LayoutAdmin.vue"

// Legacy jquery dependencies. Should replace with
// proper implementations for the current framework.
import 'jquery'
// Need to load bootstrap to make summernote works properly.
import 'bootstrap'
import 'summernote'
import 'select2'

import './style.scss'
  
const app = createApp(App)

app.use(store)
app.use(router)
app.component("Layout", Layout)
app.component('v-chart', ECharts)

app.config.globalProperties.$filters = {
  formatDate(value){
    if(!value) {
      return '';
    }

    return formatDate(value)
  }
}

setupHTTPClient()
  .finally(() => {
    app.mount('#app')
  })

