<!-- PassThrough.vue -->
<template>
  <router-view />
</template>
<script>
import userService from "@/services/userService";

export default {
  name: `PassTrough`,
  async beforeRouteEnter(to, from, next) {
    const isAuthenticated = await userService.verifiLogin();

    if (to.name !== "Login" && !isAuthenticated) {
      next({ name: "Login" });
    } else {
      next();
    }
  },
};
</script>
