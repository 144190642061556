import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import PassTrought from './PassThrough.vue';
const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue')
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: PassTrought,
    redirect: '/dashboard/stations',
    children: [
      {
        path: 'stations',
        component: () => import(/* webpackChunkName: "users" */ '../views/Stations.vue')
      },
      {
        path: 'users',
        component: () => import(/* webpackChunkName: "users" */ '../views/Users.vue')
      },
      {
        path: 'points',
        component: () => import(/* webpackChunkName: "points" */ '../views/Points.vue')
      },
      {
        path: 'news',
        component: () => import(/* webpackChunkName: "news" */ '../views/News.vue'),
        name: 'News'
      },
      {
        path: 'news/edit/:new_id',
        component: () => import(/* webpackChunkName: "news-edit" */ '../views/NewsEdit.vue'),
        name: 'NewsEdit'
      },
      {
        path: 'news/create',
        component: () => import(/* webpackChunkName: "news-create" */ '../views/NewsCreate.vue'),
        name: 'NewsCreate'
      },
      {
        path: 'faqs',
        component: () => import(/* webpackChunkName: "faqs" */ '../views/Faqs.vue'),
        name: 'Faqs'
      },
      {
        path: 'faqs/edit/:faq_id',
        component: () => import(/* webpackChunkName: "faqs-edit" */ '../views/FaqsEdit.vue'),
        name: 'FaqsEdit'
      },
      {
        path: 'faqs/create',
        component: () => import(/* webpackChunkName: "faqs-create" */ '../views/FaqsCreate.vue'),
        name: 'FaqsCreate'
      },
      {
        path: 'indicators',
        component: () => import(/* webpackChunkName: "indicators" */ '../views/Indicators.vue')
      },
      {
        path: 'tickets',
        component: () => import(/* webpackChunkName: "tickets" */ '../views/Tickets.vue'),
        name: 'Tickets'
      },
      {
        path: 'tickets/edit/:ticket_id',
        component: () => import(/* webpackChunkName: "tickets-edit" */ '../views/TicketsEdit.vue'),
        name: 'TicketsEdit'
      },
      {
        path: 'coaches',
        component: () => import(/* webpackChunkName: "coaches" */ '../views/Coaches/Coaches.vue'),
        name: 'Coaches'
      },
      {
        path: 'coaches/create',
        component: () => import(/* webpackChunkName: "coaches" */ '../views/Coaches/CoachesCreateUpdate.vue'),
        name: 'CoachesCreate'
      },
      {
        path: 'coaches/edit/:coach_id',
        component: () => import(/* webpackChunkName: "coaches" */ '../views/Coaches/CoachesCreateUpdate.vue'),
        name: 'CoachesEdit'
      },
      {
        path: 'coaches/delete/:coach_id',
        component: () => import(/* webpackChunkName: "coaches" */ '../views/Coaches/CoachesDelete.vue'),
        name: 'CoachesDelete'
      },
      {
        path: 'admin-coaches',
        component: () => import(/* webpackChunkName: "coaches" */ '../views/Admin-Coaches/AdminCoaches.vue'),
        name: 'AdminCoaches'
      },
      {
        path: 'sellers',
        component: () => import(/* webpackChunkName: "sellers" */ '../views/Sellers/Sellers.vue'),
        name: 'Sellers'
      },
      {
        path: 'sellers/create',
        component: () => import(/* webpackChunkName: "sellers" */ '../views/Sellers/SellersCreateUpdate.vue'),
        name: 'SellersCreate'
      },
      {
        path: 'Sellers/edit/:coach_id',
        component: () => import(/* webpackChunkName: "sellers" */ '../views/Sellers/SellersCreateUpdate.vue'),
        name: 'SellersEdit'
      },
      {
        path: 'sellers/delete/:coach_id',
        component: () => import(/* webpackChunkName: "sellers" */ '../views/Sellers/SellersDelete.vue'),
        name: 'SellersDelete'
      },
      {
        path: 'visits',
        component: () => import(/* webpackChunkName: "sellers" */ '../views/Visits.vue'),
        name: 'Visits'
      },
      {
        path: 'create-or-edit-visit',
        component: () => import(/* webpackChunkName: "sellers" */ '../views/CreateOrEditVisit.vue'),
        name: 'Visits Crud'
      }
      ,
      {
        path: 'surveys',
        component: () => import(/* webpackChunkName: "sellers" */ '../views/Surveys/SurveyList.vue'),
        name: 'Surveys'
      },
      {
        path: 'surveys/new',
        component: () => import(/* webpackChunkName: "sellers" */ '../views/Surveys/SurveyCreate.vue'),
        name: 'survey-create'
      },
      {
        path: 'surveys/edit/:id',
        component: () => import(/* webpackChunkName: "sellers" */ '../views/Surveys/SurveyEdit.vue'),
        name: 'survey-edit'
      },
      {
        path: 'training',
        component: () => import(/* webpackChunkName: "training" */ '../views/Training.vue'),
        name: 'training'
      },
      {
        path: 'training/edit/:new_id',
        component: () => import(/* webpackChunkName: "news-edit" */ '../views/TrainingContentEdit.vue'),
        name: 'trainingEdit'
      },
      {
        path: 'training/create',
        component: () => import(/* webpackChunkName: "news-create" */ '../views/TrainingContentCreate.vue'),
        name: 'trainingCreate'
      },
      {
        path: 'banners',
        component: () => import(/* webpackChunkName: "banners" */ '../views/Banners.vue'),
        name: 'banners'
      },
      {
        path: 'banners/edit/:id',
        component: () => import(/* webpackChunkName: "news-edit" */ '../views/BannersContentEdit.vue'),
        name: 'bannersEdit'
      },
      {
        path: 'banners/create',
        component: () => import(/* webpackChunkName: "news-create" */ '../views/BannersContentCreate.vue'),
        name: 'bannersCreate'
      },
    ]
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
