<template>
  <div class="wrapper" style="min-height: 100vh">
    <Menu />
    <div class="container-wrapper pt-4" style="min-height: 90vh">
      <slot />
    </div>
    <div class="content-wrapper" style="min-height: auto">
      <p class="text-center text-secondary">
        All rights reserved -
        <span class="fst-italic">{{version || 'development-local'}}</span>
      </p>
    </div>
  </div>
</template>

<script>
import config from '@/config/config';
import Menu from "../components/Menu";

export default {
  name: `LayoutDefault`,
  components: {
    Menu,
  },
  data() {
    return {
      version: process.env.VUE_APP_VERSION,
      config: config.getConfig(),
      user: null,
    };
  },
};
</script>

