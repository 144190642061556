import axios from 'axios';
import { APIError } from "./errors";

export async function setupHTTPClient() {
  // By now include the credencials in every request.
  // This should make the the CSRF validation works.
  axios.defaults.withCredentials = true;

  // We should make the CSRF validation works, by know it will
  // never works with that implementation, we should make a
  // request queue or something like this to fetch the CSRF token
  // before any request by the application is made. This is really
  // easy to implement but other clients will stop working.
  //
  // import config from "@/config/config"
  // const apiURL = config.getConfig().apiUrl.split('/')
  // apiURL.pop()
  // await axios.get(`${apiURL.join('/')}/sanctum/csrf-cookie`)

  return axios
}

export function handleHTTPError(e) {
  if (!e.response || e.response.status !== 400) {
    throw e
  }

  throw APIError.fromRecord(
    'Invalid arguments in the API call',
    e.response.data.error,
  )
}
