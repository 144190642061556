import ECharts from 'vue-echarts'
import { use as eChartsUse } from "echarts/core"
import { CanvasRenderer } from 'echarts/renderers'
import { BarChart } from 'echarts/charts'
import {
  GridComponent,
  TooltipComponent
} from 'echarts/components'

eChartsUse([
  CanvasRenderer,
  BarChart,
  GridComponent,
  TooltipComponent
])

export default ECharts
