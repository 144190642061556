export interface Validation {
  field: string;
  message: string;
}

export class APIError extends Error {
  public name = 'APIError'
  public fields: Validation[] = []

  constructor(message: string, fields: Validation[]) {
    super(message)
    this.fields = fields
  }

  static fromRecord(message: string, response: Record<string, string[]>) {
    const fields = Object.keys(response).map(field => {
      return {
        field,
        message: response[field][0]
      }
    })

    return new APIError(message, fields)
  }
}
